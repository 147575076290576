import { ICellRendererParams } from "ag-grid-community";
import React from "react";

class IsActiveCellRenderer extends React.Component<ICellRendererParams, any> {
    render() {
        return (<div>
            {this.props.value == "0" && <span className="badge bg-danger">Offline</span>}
            {this.props.value == "1" && <span className="badge bg-success">Online</span>}
        </div>)
    }
}

export default IsActiveCellRenderer;