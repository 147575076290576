import { Button, Card, Form, FormInstance, Input, notification, Select, Tabs } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { debounce } from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import { DevicesClient, FranchiseeDTO, DeviceDTO, CreateFranchiseeDTO, FranchiseeClient, UpdateFranchiseeDTO, Role } from "../../api/BikeCafeApi";
import AuthService from "../../services/AuthService.service";
import FranchiseeUsers from "./Components/Users";

import "./franchseeDetails.scss";
import FranchiseeStatistics from "./franchseeStatitics";

interface IFranchiseeDetailsState {
    entity: FranchiseeDTO,
    devices: DeviceDTO[],
    saving: boolean
}

class FranchiseeDetails extends React.Component<any, IFranchiseeDetailsState> {
    deviceApi: DevicesClient = new DevicesClient();
    frenchiseeApi: FranchiseeClient = new FranchiseeClient();
    form = React.createRef<FormInstance<any>>();
    authService: AuthService = new AuthService();

    constructor(props: any) {
        super(props);
        this.state = {
            entity: new FranchiseeDTO(),
            devices: [],
            saving: false
        }
    }



    get isNew() {
        return this.props.franchiseeId == null;
    }

    mainDataUpdate = async (changedValues: any, allValues: any) => {

    }

    async componentDidMount() {
        let devices = (await this.deviceApi.list());
        this.setState({
            devices: devices
        });
        if (!this.isNew) {
            let franchisee = await this.frenchiseeApi.get(this.props.franchiseeId);
            this.setState({ entity: franchisee });
            this.form.current?.resetFields();
        }
    }

    onSave = async (values: any) => {
        this.setState({ saving: true })
        var devices: DeviceDTO[] = (values.devices as number[]).map(devId => {
            let dev: DeviceDTO = (this.state.devices.find(x => x.id === devId) as DeviceDTO);
            return DeviceDTO.fromJS({ id: dev.id, isActivated: dev.isActivated, timeForKg: 0 });
        });

        if (this.isNew) {
            var createFranchisee = CreateFranchiseeDTO.fromJS({
                id: 0,
                description: values.description,
                identifier: values.identifier,
                name: values.name,
                coffeeDelta: values.coffeeDelta,
                devices: devices
            });
            var response = await this.frenchiseeApi.create(createFranchisee);
            this.props.history.push(`/franchisees/${response.id}`)
        } else {
            var updateFranchise = UpdateFranchiseeDTO.fromJS({
                id: this.state.entity.id,
                description: values.description,
                identifier: values.identifier,
                name: values.name,
                coffeeDelta: values.coffeeDelta,
                devices: devices
            });
            notification['success']({
                message: 'Dane zostały zapisane.',
            });
            var response = await this.frenchiseeApi.update(updateFranchise);
        }
        this.setState({ saving: false })
    }

    get defaultFormValues() {
        if (this.isNew) {
            return { identifier: "", name: "", description: '', devices: [] };
        } else {
            return {
                identifier: this.state.entity.identifier,
                name: this.state.entity.name,
                description: this.state.entity.description,
                devices: this.state.devices.filter(x => x.franchiseeId === this.state.entity.id).map(x => x.id),
                coffeeDelta: this.state.entity.coffeeDelta
            }
        }
    }

    render() {
        return (
            <div className="franchseeDetails">
                {this.isNew && <h2 style={{ margin: 0 }}>Nowy franczyzobiorca</h2>}
                {!this.isNew && <h2 style={{ margin: 0 }}>Szczegóły</h2>}
                <Tabs defaultActiveKey={this.authService.getRole() == Role.Admin ? "1" : "2"}>
                    {this.authService.getRole() == Role.Admin && <Tabs.TabPane tab="Dane" key="1">
                        <Form
                            onValuesChange={debounce(this.mainDataUpdate, 500)}
                            ref={this.form}
                            name="basic"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            initialValues={this.defaultFormValues}
                            autoComplete="off"
                            onFinish={this.onSave}
                        >
                            <Card title="Informacje podstawowe" >
                                <Form.Item
                                    label="Identyfikator"
                                    name="identifier"
                                    rules={[{ required: true, message: 'Identyfikator jest wymagany' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Nazwa kontrahenta"
                                    name="name"
                                    rules={[{ required: true, message: 'Nazwa jest wymagana' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Opis"
                                    name="description"
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                                <Form.Item label="Urządzenia" name="devices">
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Wybierz urządzenia należące do tego francyzobiorcy"
                                        optionLabelProp="label"
                                        defaultValue={[1, 2]}
                                    >
                                        {this.state.devices.map((device, index) => {
                                            return (<Select.Option label={device.name} key={"device-" + device.id} value={device.id as any} >
                                                {device.name}
                                            </Select.Option >)
                                        })}
                                    </Select>
                                </Form.Item>
                            </Card>
                            <Card title="Ustawienia kawy" >
                                <Form.Item
                                    label="Ilość startowa [kg]"
                                    name="coffeeDelta"
                                    rules={[{ required: true, message: 'Wartość startowa jest wymagany' }]}>
                                    <Input />
                                </Form.Item>
                            </Card>
                            <Card style={{ textAlign: "right" }}>
                                <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                                    <Button loading={this.state.saving} type="primary" htmlType="submit">
                                        Zapisz
                                    </Button>
                                </Form.Item>
                            </Card>
                        </Form>
                    </Tabs.TabPane>
                    }
                    {/* {!this.isNew && <Tabs.TabPane tab="Zamówienia" key="2">
                        Content of Tab Pane 2
                    </Tabs.TabPane>} */}
                    {!this.isNew && <Tabs.TabPane tab="Statystyki" key="3">
                        <FranchiseeStatistics franchiseeId={this.props.franchiseeId} />
                    </Tabs.TabPane>}
                    {!this.isNew && <Tabs.TabPane tab="Użytkownicy" key="4">
                        <FranchiseeUsers franchiseeId={this.props.franchiseeId} />
                    </Tabs.TabPane>}
                    {/* {!this.isNew && <Tabs.TabPane tab="Zmielenia" key="4">
                        Content of Tab Pane 3
                    </Tabs.TabPane>} */}
                </Tabs>

            </div>

        );
    }
}


export default withRouter(FranchiseeDetails);