import { DeleteOutlined, DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridReadyEvent } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Button, Card, DatePicker, Dropdown, Menu, Modal } from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { AuthClient, GrindClient, Role } from "../../../api/BikeCafeApi";
import AuthService from "../../../services/AuthService.service";

import "./DeviceMills.scss";

export interface IDeviceGrindings {
    deviceId: number
}

const dateFormatter = (params: any) => {
    return params.value.format("YYYY-MM-DD HH:mm:ss");
}

const timeFormatter = (params: any) => {
    return (params.value / 1000).toFixed(3) + " [s]";
}

class DeviceGrindings extends React.Component<IDeviceGrindings, any> {
    state = {
        grindings: [],
        isDownloadModalOpen: false,
        range: [moment().startOf('month'), moment()],
    }

    grindingClient: GrindClient = new GrindClient();

    async componentDidMount() {
        await this.populateGrinds();
    }

    async populateGrinds() {
        var grinds = await this.grindingClient.listForDevice(this.props.deviceId);
        this.setState({
            grindings: grinds
        });
    }

    onGridReady = (event: GridReadyEvent) => {
        event.api.sizeColumnsToFit();
    }

    onDeleteAll = async () => {
        await this.grindingClient.deleteAllForDevice(this.props.deviceId);
        await this.populateGrinds();
    }

    onDownload = async () => {
        this.setState({ isDownloadModalOpen: true });
    }

    onDownloadStart = async () => {
        var response: any = await this.grindingClient.exportDeviceData(this.props.deviceId, this.state.range[0], this.state.range[1]);
        console.log(response);
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = response.fileName;
        link.click();
        this.setState({ isDownloadModalOpen: false });
    }

    onChangeRange = (range: [Moment, Moment]) => {
        this.setState({ range: [range[0], range[1]] });
    }


    render() {
        return (<Card title="Pomiary urządzenia" className="deviceMills ag-theme-alpine" >
            <AgGridReact
                suppressHorizontalScroll={true}
                onGridReady={this.onGridReady}
                rowData={this.state.grindings}>
                <AgGridColumn width={50} field="createDate" headerName="Data" resizable={true} valueFormatter={dateFormatter}></AgGridColumn>
                <AgGridColumn width={50} field="time" headerName="Czas trwania" resizable={true} valueFormatter={timeFormatter}></AgGridColumn>
                <AgGridColumn headerName="" headerComponentFramework={DeviceGrindinsHeaderRenderer} headerComponentParams={{
                    onDeleteAll: this.onDeleteAll,
                    onDownload: this.onDownload

                }}></AgGridColumn>
            </AgGridReact>
            <Modal onCancel={() => this.setState({ isDownloadModalOpen: false })} title="Podbieranie pomiarów" visible={this.state.isDownloadModalOpen} footer={[
                <Button type="primary" key="back" onClick={() => this.onDownloadStart()}>Pobierz</Button>,
                <Button type="default" key="back" onClick={() => this.setState({ isDownloadModalOpen: false })}>Anuluj</Button>
            ]}>
                Zakres dat: <DatePicker.RangePicker value={this.state.range as any} onChange={(value: any, dateStrings) => this.onChangeRange(value)} />
            </Modal>
        </Card>)
    }
}

class DeviceGrindinsHeaderRenderer extends React.Component<any, any> {
    authClient: AuthService = new AuthService();


    deleteAllGrinds = () => {
        Modal.confirm({
            title: 'Czy na pewno chcesz usunąć wszystkie pomiary?',
            icon: <DeleteOutlined />,
            content: '',
            okText: 'Tak',
            okType: 'danger',
            cancelText: 'Nie',
            onOk: async () => {
                await this.props.onDeleteAll();
            },
            onCancel: () => { },
        });
    }

    get menu() {
        return (<Menu>
            {this.authClient.getRole() == Role.Admin && <Menu.Item onClick={() => this.deleteAllGrinds()} key="1" icon={<DeleteOutlined />}>
                Usuń wszystkie pomiary
            </Menu.Item>}
            <Menu.Item onClick={() => this.props.onDownload()} key="2" icon={<DownloadOutlined />}>
                Pobierz pomiary
            </Menu.Item>
        </Menu>)
    }

    render() {
        return (<div style={{ textAlign: "right", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Dropdown overlay={this.menu}>
                <Button>
                    Menu <DownOutlined />
                </Button>
            </Dropdown>
        </div>)
    }
}

export default DeviceGrindings;