import { GridReadyEvent } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React from "react";
import { DeviceDTO, DevicesClient, FranchiseeClient, FranchiseeDTO, IDeviceDTO, IFranchiseeDTO } from "../../api/BikeCafeApi";
import AddHeaderRenderer from "../../components/AgGrid/AddHeaderRenderer";
import DeviceMenuRenderer from "../../components/AgGrid/DeviceMenuRenderer";
import IsActiveCellRenderer from "../../components/AgGrid/IsActiveRenderer";
import Navbar from "../../components/Navbar/Navbar";
import MainLayout from "../../layouts/MainLayout";
import AddDeviceModal, { INewDevice } from "../../components/Modals/AddDeviceModal/AddDeviceModal"
import { Card, Modal } from "antd";

import "./franchisee.scss"
import { ExclamationCircleOutlined } from "@ant-design/icons";
import IsActivatedCellRenderer from "../../components/AgGrid/IsActivatedRendere";

export interface IFranchiseeState {
    entities: IFranchiseeDTO[],
    visible: {
        addDeviceModal: boolean
    },
    editDevice?: DeviceDTO,
}

enum ModalType { AddDeviceModal }

class Franchisees extends React.Component<any, IFranchiseeState> {

    apiClient: FranchiseeClient = new FranchiseeClient();

    constructor(props: any) {
        super(props);
        this.state = {
            visible: {
                addDeviceModal: false
            },
            entities: [],
        }
    }

    async componentDidMount() {
        var data = await this.apiClient.list();
        this.setState({
            entities: data
        });
    }

    onGridReady = (event: GridReadyEvent) => {
        event.api.sizeColumnsToFit();
    }


    onEdit = (device: DeviceDTO) => {
        this.setState({
            editDevice: device,
            visible: {
                ...this.state.visible,
                addDeviceModal: true
            }
        });
    }

    onDetails = (entity: IFranchiseeDTO) => {
        this.props.history.push(`/franchisees/${entity.id}`)
    }


    goToCreate = () => {
        this.props.history.push(`/franchisees/new`)
    }

    changeVisible = (modal: ModalType, visible: boolean) => {
        let v = { ...this.state.visible }
        if (modal === ModalType.AddDeviceModal) {
            v.addDeviceModal = visible;
        }
        this.setState({ visible: v });
    }

    render() {
        return (
            <>
                <div className="row h-100">
                    <div className="col">
                        <Card title="Franczyzobiorcy" className="w-100 h-100">
                            <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
                                <AgGridReact
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.entities}>
                                    <AgGridColumn filter={true} field="name" headerName="Nazwa franczyzobiorcy" resizable={true}></AgGridColumn>
                                    <AgGridColumn field="id" headerName="" resizable={true}
                                        width={30}
                                        cellRendererFramework={DeviceMenuRenderer}
                                        cellRendererParams={{
                                            onDetails: this.onDetails,
                                        }}
                                     headerComponentFramework={AddHeaderRenderer}
                                     headerComponentParams={{
                                         onCreate: this.goToCreate
                                     }}
                                    ></AgGridColumn>
                                </AgGridReact>
                            </div>
                        </Card>
                    </div>
                </div>
            </>
        )
    }
}

export default Franchisees;