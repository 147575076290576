
import React from "react";
import { Layout, Menu } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    PoweroffOutlined,
    TeamOutlined,
} from '@ant-design/icons';

import styles from "./MainLayout.module.scss";

import logo from "../assets/img/logo_login.png";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { removeToken } from "../redux/reducers/auth.actions";
import AuthService from "../services/AuthService.service";
import { Role } from "../api/BikeCafeApi";

const { Header, Sider, Content } = Layout;

class MainLayout extends React.Component<any, any> {
    authService: AuthService = new AuthService();

    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    logout = () => {
        this.props.logout();
    }

    get userName() {
        return this.authService.getUserName();
    }

    render() {
        return (
            <Layout style={{ minHeight: "100%" }}>
                <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                    <div className={styles.logo}>
                        <img src={logo} />
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['2']}>
                        {this.authService.getRole() == Role.Admin && <Menu.Item key="1" icon={<TeamOutlined />}>
                            <Link to="/franchisees">
                                Franczyzobiorcy
                            </Link>

                        </Menu.Item>}
                        {this.authService.getRole() == Role.Owner && < Menu.Item key="4" icon={<TeamOutlined />}>
                            <Link to={`/franchisees/${this.authService.getFranchiseeId()}`}>
                                Moje dane
                            </Link>

                        </Menu.Item>
                        }
                        <Menu.Item key="2" icon={<UserOutlined />}>
                            <Link to="/devices">
                                Urządzenia
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<VideoCameraOutlined />}>
                            <Link to="/grindings">
                                Pomiary
                            </Link>

                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className={styles.header} style={{ padding: 0 }}>
                        {this.state.collapsed ? <MenuUnfoldOutlined className={styles.trigger} onClick={this.toggle} /> : <MenuFoldOutlined onClick={this.toggle} className={styles.trigger} />}
                        {this.props.token ? <div onClick={() => this.logout()} className="d-flex align-items-center justify-content-center" style={{ paddingRight: 16, cursor: "pointer" }}>
                            {this.userName} <PoweroffOutlined className="ms-2" />
                        </div> : ""}
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 12,
                        }}
                    >
                        {this.props.children}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        token: state.auth.token
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => dispatch(removeToken())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
