import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, RouteProps } from 'react-router-dom';

class AuthRoute extends React.Component<any, any> {
    render() {
        return (
            <Route {...this.props} render={props => {
                if (this.props.auth.token) {
                    return this.props.render(props);
                } else {
                    return <Redirect to="/login" />
                }
            }} />
        )
    }
}

const mapStateToProps = (props: any) => {
    return props;
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(AuthRoute) as any;