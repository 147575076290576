import { remove, set } from "local-storage";
import AuthService from "../../services/AuthService.service";


export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export function setToken(token: string) {
    if (token) {
        set<string>(AuthService.tokenKey, token);
    }
    return {
        type: LOGIN,
        payload: token
    }

}

export function removeToken() {
    remove(AuthService.tokenKey);
    return {
        type: LOGOUT
    }
}