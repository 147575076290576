import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk"
import reducers from "./reducers";

const middlewares = [thunk];

export function configureStore(initialState: any): any {
    const store = createStore(
        reducers,
        initialState,
        compose(applyMiddleware(...middlewares))
    );

    return store;
}

export default configureStore;