import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react";

class AddHeaderRenderer extends React.Component<any, any> {
    render() {
        return (<div style={{ textAlign: "right" }}>
            <button onClick={() => this.props.onCreate()} className="btn btn-success btn-sm"><FontAwesomeIcon icon={faPlus} fixedWidth={true} />  Dodaj</button>
        </div>)
    }
}

export default AddHeaderRenderer;