import { Card, Col, FormInstance, InputNumber, Modal, notification, Row } from "antd";
import React from "react";
import { DeviceDTO, DevicesClient, DeviceSettingDTO, LogType, OnlineState, Role, SetSettingsDTO } from "../../api/BikeCafeApi";
import { Form, Input, Button, Checkbox, Switch } from 'antd';
import { debounce } from "lodash";
import { Tabs } from 'antd';
import * as signalr from "@microsoft/signalr";
import TextArea from "antd/lib/input/TextArea";
import DeviceLogs from "./DeviceLogs/DeviceLogs";

import "./deviceDetails.scss";
import DeviceGrindings from "./DeviceMills/DeviceMills";
import AuthService from "../../services/AuthService.service";


const { TabPane } = Tabs;

interface IDeviceDetailsState {
    device?: DeviceDTO,
    settings?: DeviceSettingDTO,
    liveData: boolean,
    liveDataState: boolean,
    liveDataValue: number,
    liveDataMax?: number,
    liveDataMin?: number,
    calibrationView: boolean,
    calibrationStep: 1 | 2 | 3 | 4 | 5 | 6 | 7,
    calibrationData: number[],
    calibrationGetData: boolean,
    calibrationTime: number,
    calibrationScore: {
        idle?: number,
        power?: number,
        idleValue?: number
    },
    saving: boolean
}

class DeviceDetails extends React.Component<any, IDeviceDetailsState>{

    apiClient: DevicesClient = new DevicesClient();
    authService: AuthService = new AuthService();

    form = React.createRef<FormInstance<any>>();
    settingsForm = React.createRef<FormInstance<any>>();
    connection = new signalr.HubConnectionBuilder()
        .withUrl("/liveData")
        .build();
    constructor(props: any) {
        super(props);
        this.state = {
            saving: false,
            liveData: false,
            liveDataState: false,
            liveDataValue: 0,
            calibrationView: false,
            calibrationStep: 1,
            calibrationData: [],
            calibrationTime: 0,
            calibrationGetData: false,
            calibrationScore: {}
        }


    }

    async componentDidMount() {
        var deviceId = this.props.match.params.deviceId;
        var device = await this.apiClient.get(deviceId);
        this.setState({ device: device })
        this.form.current?.setFieldsValue({
            name: device.name,
            bcmsId: device.bcmsId,
            millIdentifier: device.millIdentifier,
            posIdentifier: device.posIdentifier,
            key: device.key,
            isActivated: device.isActivated,
            description: device.description,
            timeForKg: device.timeForKg
        });

        this.apiClient.getSettings(deviceId).then(settings => {
            this.setState({ settings: settings });
            this.settingsForm.current?.setFieldsValue({
                idle: settings.idle
            });

        });

        this.connection.on(`LiveData-${deviceId}`, data => {
            if (!this.state.liveData) {
                this.setState({ liveData: true });
            }
            if ((this.state.liveDataMax && data.value > this.state.liveDataMax) || this.state.liveDataMin == null) {
                this.setState({ liveDataMax: data.value })
            }

            if ((this.state.liveDataMin && data.value < this.state.liveDataMin) || this.state.liveDataMin == null) {
                this.setState({ liveDataMin: data.value })
            }

            if (this.state.calibrationGetData) {
                let calibrationData = [...this.state.calibrationData];
                calibrationData.push((data.value as any));
                this.setState({ liveDataValue: data.value, liveDataState: data.state, calibrationData: calibrationData });
            } else {
                this.setState({ liveDataValue: data.value, liveDataState: data.state });
            }
        });

        await this.connection.start();
    }

    componentWillUnmount() {

    }

    mainDataUpdate = async () => {
        this.form.current?.validateFields().then(async values => {
            var updateDevice: DeviceDTO = (this.state.device as DeviceDTO).clone();
            updateDevice.bcmsId = values.bcmsId;
            updateDevice.posIdentifier = values.posIdentifier;
            updateDevice.millIdentifier = values.millIdentifier;
            updateDevice.name = values.name;
            updateDevice.isActivated = values.isActivated;
            updateDevice.description = values.description;
            updateDevice.timeForKg = values.timeForKg;
            let device = await this.apiClient.update(updateDevice)
            this.setState({ device: device })
        }).catch(() => { });
    }

    settingsUpdate = async () => {
        this.settingsForm.current?.validateFields().then(async values => {
            if (this.state.device != null) {
                var data = this.apiClient.setSettings((this.state.device as DeviceDTO).key || "", new SetSettingsDTO({ idle: values.idle }));
            }

        }).catch(() => { });
    }

    onChangeLiveData = async (checked: boolean) => {
        await this.apiClient.liveData((this.state.device as DeviceDTO).key || "", checked);
        await this.connection.stop();
        this.setState({
            liveData: checked
        }, async () => {
            await this.connection.start()
        })
    }

    formatNumber(value: number) {
        return value.toFixed(4);
    }

    onClickStartCalibrationStep2 = () => {
        this.setState({ calibrationStep: 2, calibrationTime: 10, calibrationData: [] });
        var countInterval = setInterval(() => {
            if (!this.state.calibrationView) {
                clearInterval(countInterval);
                return;
            }

            if (this.state.calibrationTime === 1) {
                this.setState({ calibrationGetData: false });
            } else if (this.state.calibrationTime === 0) {
                clearInterval(countInterval);

                let currentData = { ...this.state.calibrationScore };
                currentData.idle = this.state.calibrationData.reduce((p, c) => p + c, 0) / this.state.calibrationData.length;

                this.setState({ calibrationStep: 3, calibrationScore: currentData, calibrationData: [] });
                //TODO: set value
            } else if (this.state.calibrationTime === 9) {
                this.setState({ calibrationGetData: true })
            }
            this.setState({ calibrationTime: this.state.calibrationTime - 1 });
        }, 1000);
    }

    onClickStartCalibrationStep4 = () => {
        this.setState({ calibrationStep: 4, calibrationTime: 5, calibrationData: [] });
        let interval = setInterval(() => {
            if (this.state.calibrationTime === 0) {
                clearInterval(interval);
                this.onClickStartCalibrationStep5();
            }
            this.setState({ calibrationTime: this.state.calibrationTime - 1 });
        }, 1000)
    }

    onClickStartCalibrationStep5 = () => {
        this.setState({ calibrationStep: 5, calibrationTime: 10 });
        let countInterval = setInterval(() => {
            if (!this.state.calibrationView) {
                clearInterval(countInterval);
                return;
            }

            if (this.state.calibrationTime == 8) {
                this.setState({ calibrationGetData: true })
            }
            else if (this.state.calibrationTime === 2) {
                this.setState({ calibrationGetData: false })
            }
            else if (this.state.calibrationTime === 0) {
                clearInterval(countInterval);
                let currentData = { ...this.state.calibrationScore };
                currentData.power = this.state.calibrationData.reduce((p, c) => p + c, 0) / this.state.calibrationData.length;

                if (currentData.idle != null && currentData.power != null) {
                    currentData.idleValue = currentData.idle + ((currentData.power - currentData.idle) * 0.33);
                }

                this.setState({ calibrationStep: 6, calibrationScore: currentData });


            }
            this.setState({ calibrationTime: this.state.calibrationTime - 1 });
        }, 1000)
    }

    closeCalibration = () => {
        this.setState({ calibrationScore: {}, calibrationTime: 0, calibrationGetData: false, calibrationData: [], calibrationView: false, calibrationStep: 1 });
    }

    onSave = async () => {
        this.setState({ saving: true });
        await this.mainDataUpdate();
        if (this.state.device?.onlineState === OnlineState.Online) {
            await this.settingsUpdate();
        }
        notification['success']({
            message: 'Dane zostały zapisane.',
        });
        this.setState({ saving: false });
    }

    get networkInfo() {
        if (this.state.settings == null || this.state.settings.network == null) {
            return [];
        }

        let data: any[] = [];
        Object.keys(this.state.settings?.network).forEach((key) => {

            let interfaceInfo = this.state.settings!.network![key];
            interfaceInfo.forEach(info => {
                data.push({ interface: key, family: info.family, address: info.address, netmask: info.netmask, mac: info.mac })
            });


        })
        return data;
    }

    getInterfaceName = (iface: string) => {
        switch (iface) {
            case "wlan0": return "Wifi";
            case "eth0": return "Połączenie przewodowe";
            case "eth1": return "Połączenie z modemem";
            case "lo": return "Pętla zwrotna";
            default: return iface;
        }
    }

    render() {
        return (<div className="deviceDetails" style={{ minHeight: "100%", display: "flex", flexDirection: "column" }}>
            <h2>Urządzenie {this.state.device?.name} ({this.state.device?.key})</h2>
            <Tabs defaultActiveKey={this.authService.getRole() == Role.Admin ? "1" : "2"} onChange={() => { }} style={{ flex: "1 1 auto" }}>
                {this.authService.getRole() == Role.Admin && <TabPane tab="Informacje podstawowe" key="1">
                    <Form
                        ref={this.form}
                        name="basic"
                        labelCol={{ span: 2 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ name: this.state.device?.name, isActivated: false }}
                        autoComplete="off"
                    >
                        <Card title="Informacje podstawowe" >

                            <Form.Item
                                label="Nazwa"
                                name="name"
                                rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="BCMS Id"
                                name="bcmsId"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Identyfikator młynka"
                                name="millIdentifier"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Identyfikator POS"
                                name="posIdentifier"
                            >
                                <Input />
                            </Form.Item>
                            {/*<Form.Item*/}
                            {/*    label="Identyfikator"*/}
                            {/*    name="key"*/}
                            {/*    rules={[{ required: true }]}*/}
                            {/*>*/}
                            {/*    <Input disabled={true} />*/}
                            {/*</Form.Item>*/}
                            <Form.Item
                                label="Opis"
                                name="description"
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                            <Form.Item
                                label="Aktywowany"
                                name="isActivated"
                                valuePropName="checked">
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label="Status"
                            >
                                {this.state.device?.onlineState === OnlineState.Offline ? "OFFLINE" : "ONLINE"}
                            </Form.Item>

                        </Card>
                        <Card title="Ustawienia mielenia" >

                            <Form.Item
                                label="Sekund na Kg [s]"
                                name="timeForKg"
                                rules={[{ required: true, message: 'Wartość jest wymagana' }]}
                            >
                                <InputNumber />
                            </Form.Item>

                        </Card>
                    </Form>
                    <Card title="Ustawienia progu załączania" >
                        {this.state.device?.onlineState === OnlineState.Offline ? "Urządzenie jest offline" : ""}
                        {this.state.device?.onlineState === OnlineState.Online && <><Form
                            ref={this.settingsForm}
                            name="basic"
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ idle: 0 }}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Wartość progu"
                                name="idle"
                                rules={[{ required: true }]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Form>
                            <Form
                                name="basic"
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{}}
                                autoComplete="off"
                            >
                                <Form.Item label="LiveData">
                                    <Switch checked={this.state.liveData} onChange={this.onChangeLiveData} />
                                    {this.state.liveData && <div style={{ marginTop: "1rem" }}>
                                        <div>Aktualna wartość: {this.formatNumber(this.state.liveDataValue)}</div>
                                        <div>Minimalna wartość: {this.state.liveDataMin != null ? this.formatNumber(this.state.liveDataMin) : ""}</div>
                                        <div>Maksymalna wartość: {this.state.liveDataMax != null ? this.formatNumber(this.state.liveDataMax) : ""}</div>
                                        <div>Status: {this.state.liveDataState ? "ON" : "OFF"}</div>
                                        <div><Button type="primary" onClick={() => { this.setState({ calibrationView: true }) }}>Kalibracja</Button></div>
                                    </div>

                                    }
                                </Form.Item>
                            </Form>
                        </>
                        }
                    </Card>
                    <Card title="Ustawienia sieci" >
                        {this.state.settings?.network == null ? "Brak informacji" : ""}
                        {this.state.settings?.network != null ?

                            <Row>
                                {
                                    this.networkInfo.map((info: any, index: number) => {
                                        return (<Col span={6} style={{ paddingBottom: "16px" }} key={"interface-" + index}>
                                            <Row>
                                                <Col span={8} style={{ textAlign: "right", paddingRight: "8px" }}>Interfejs:</Col>
                                                <Col span={16}><strong>{this.getInterfaceName(info.interface)}</strong></Col>
                                            </Row>
                                            <Row>
                                                <Col span={8} style={{ textAlign: "right", paddingRight: "8px" }}>Typ:</Col>
                                                <Col span={16}><strong>{info.family}</strong></Col>
                                            </Row>
                                            <Row>
                                                <Col span={8} style={{ textAlign: "right", paddingRight: "8px" }}>IP:</Col>
                                                <Col span={16}><strong>{info.address}</strong></Col>
                                            </Row>
                                            <Row>
                                                <Col span={8} style={{ textAlign: "right", paddingRight: "8px" }}>Mask:</Col>
                                                <Col span={16}><strong>{info.netmask}</strong></Col>
                                            </Row>
                                            <Row>
                                                <Col span={8} style={{ textAlign: "right", paddingRight: "8px" }}>MAC:</Col>
                                                <Col span={16}><strong>{info.mac}</strong></Col>
                                            </Row>
                                        </Col>)
                                    })
                                }

                            </Row>

                            : ""}
                    </Card>
                    <Card style={{ textAlign: "right" }}>
                        <Form

                            name="basic"
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 16 }}

                            autoComplete="off"
                        >
                            <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                                <Button onClick={() => this.onSave()} type="primary" htmlType="submit" loading={this.state.saving}>
                                    Zapisz
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    <Modal bodyStyle={{ height: "300px" }} title="Kalibracja urządzenia" visible={this.state.calibrationView} footer={[<Button type="primary" key="back" onClick={() => this.closeCalibration()}>OK</Button>]}>
                        {this.state.calibrationStep == 1 && <>
                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    Nie używaj mielenia. Pozostaw urządzenia włączone.
                                    <div><Button style={{ marginTop: "1rem" }} type="primary" onClick={() => { this.onClickStartCalibrationStep2() }}>Rozpocznij test bez obciążenia</Button></div>
                                </div>
                            </div>
                        </>}
                        {(this.state.calibrationStep == 2 || this.state.calibrationStep == 5) && <>
                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    <h1>{this.state.calibrationTime}</h1>
                                </div>
                            </div>
                        </>}
                        {this.state.calibrationStep == 3 && <>
                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    <h2>Koniec testu bez obciążenia</h2>
                                    Młynek teraz powinien być zasypany kawą. Ustaw czas mielenia na 10 sekund. Przygotuj się do naciśnięcia przycisku mielena w momencie kiedy wyświetli się komunikat.
                                    <div><Button style={{ marginTop: "1rem" }} type="primary" onClick={() => { this.onClickStartCalibrationStep4() }}>Rozpocznij test z obciążeniem</Button></div>
                                </div>
                            </div>
                        </>}
                        {this.state.calibrationStep == 4 && <>
                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    <div>
                                        {this.state.calibrationTime != 0 && <h4> Naciśnij start młynka za {this.state.calibrationTime}</h4>}
                                        {this.state.calibrationTime == 0 && <h4> Naciśnij start teraz</h4>}
                                    </div>
                                </div>
                            </div>
                        </>}
                        {this.state.calibrationStep == 6 && <>
                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    <div>
                                        Średnie ustawienie bez obciążenia: <b>{this.state.calibrationScore.idle?.toFixed(2)}</b>
                                    </div>
                                    <div>
                                        Średnie ustawienie z obciążeniem: <b>{this.state.calibrationScore.power?.toFixed(2)}</b>
                                    </div>
                                    <div>
                                        <h3>Sugerowane ustawienie idle to <b>{this.state.calibrationScore.idleValue?.toFixed(2)}</b></h3>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </Modal>

                </TabPane>
                }
                {this.state.device != null &&
                    <TabPane tab="Pomiary" key="2">
                        <DeviceGrindings deviceId={this.state.device.id as any} />
                    </TabPane>
                }
                {this.state.device != null &&
                    <TabPane tab="Logi" key="3">
                        <DeviceLogs deviceId={this.state.device.id as any} logs={[LogType.OnlineStatus, LogType.OfflineStatus]} />
                    </TabPane>

                }

            </Tabs> </div>
        )
    }
}

export default DeviceDetails