import React, { useCallback } from "react";

import NiceModal, { useModal, antdModal } from '@ebay/nice-modal-react';
import { Button, Checkbox, Divider, Form, Input, Modal, Popconfirm, Select, Switch } from "antd";
import { ActiveStatus, Role, UserDTO, UsersClient } from "../../../api/BikeCafeApi";
import { useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";

export default NiceModal.create<{ user: UserDTO, franchiseeId: number }>((data) => {
    const userClient = new UsersClient();

    const modal = useModal();
    const [form] = Form.useForm();

    useEffect(() => {
        if (data.user != null) {
            form.setFieldsValue({
                login: data.user.login,
                isActive: data.user.isActive,
                role: data.user.role
            })
        }

        console.log('tt');
    }, [])


    const handleSubmit = useCallback(() => {
        form.validateFields().then(async () => {
            const formData = { ...form.getFieldsValue() };
            var user = UserDTO.fromJS(formData)
            if (!data.user) {
                user.id = 0;
                user.franchiseeId = data.franchiseeId;
                user.isActive = ActiveStatus.Active;

                await userClient.create(user);

            } else {
                user.id = data.user.id;
                user.franchiseeId = data.user.franchiseeId;
                await userClient.update(user);
            }
            modal.resolve(user);
            modal.hide();
        });
    }, [modal, data.user, form]);

    const onConfirmDelete = async () => {
        if (data.user) {
            await userClient.delete(data.user.id as number);
            modal.hide();
        }
        
    }

    return (
        <Modal
            {...antdModal(modal)}
            title={data.user ? 'Zmiana danych' : 'Nowy użytkownik'}
            okText={data.user ? 'Zapisz' : 'Dodaj'}
            onOk={handleSubmit}
        >
            <Form form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    label="Login"
                    name="login"
                    rules={[{ required: true, message: 'Wartość wymagana' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Hasło"
                    name="password"
                    rules={[{ required: !data.user, message: 'Wartość wymagana' }]}


                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Rola"
                    name="role"
                    rules={[{ required: true, message: 'Wartość wymagana' }]}
                >
                    <Select>
                        <Select.Option value={Role.Owner}>Właściciel</Select.Option>
                        <Select.Option value={Role.User}>Sprzedawca</Select.Option>
                    </Select>
                </Form.Item>
                {data.user != null && <><Form.Item
                    label="Aktywny"
                    name="isActive"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item></>
                }
            </Form>
        </Modal>
    );
});