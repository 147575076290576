import React from "react";
import { Modal, Button, Input, Form, FormInstance } from 'antd';
import { DevicesClient, DeviceDTO } from "../../../api/BikeCafeApi";

export interface INewDevice {
    name: string,
    key: string
}

export interface IAddDeviceModalProps {
    visible: boolean,
    handleOk: (device: DeviceDTO) => void
    handleCancel: () => void,
    device?: DeviceDTO
}

export interface IAddDeviceModalState {

}

export class AddDeviceModal extends React.Component<IAddDeviceModalProps, any> {

    deviceClient: DevicesClient = new DevicesClient();

    form = React.createRef<FormInstance<any>>();

    state = {

    }

    async componentDidMount() {

    }

    defaulValues = {
        id: 0,
        name: "",
        bcmsId: "",
        key: ""
    }

    async componentDidUpdate(prevProps: any) {
        if (prevProps.visible != this.props.visible) {
            if (this.props.device == null) {
                var guid = await this.deviceClient.getNewId();
                this.form.current?.setFieldsValue({ ...this.defaulValues, key: guid });
            } else {
                this.form.current?.setFieldsValue({ ...this.props.device });
            }

        }
    }

    handleCancel = () => {
        this.props.handleCancel();
    }

    onSubmit = async () => {
        this.form.current?.validateFields().then(values => {
            if (this.props.device) {
                var v = DeviceDTO.fromJS({ ...this.props.device, ...values });
                this.props.handleOk(v);
            } else {
                this.props.handleOk(values);
            }

        });
    }


    render() {
        return (<>
            <Modal title="Dodawanie urządzenia" visible={this.props.visible} onOk={() => this.onSubmit()} onCancel={this.handleCancel}>
                <Form
                    ref={this.form}
                    name="basic"
                    initialValues={{ name: '' }}
                    labelCol={{ span: 6 }}

                >
                    <Form.Item
                        label="Nazwa"
                        name="name"
                        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="BCMS Id"
                        name="bcmsId"
                        rules={[{ required: true, message: 'BCMS ID jest wymagany' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Identyfikator"
                        name="key"
                        rules={[{ required: true }]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                </Form>
            </Modal>
        </>);
    }
}

export default AddDeviceModal;