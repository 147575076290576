import React from 'react';
import { DevicesClient } from './api/BikeCafeApi';
import './App.scss';
import AuthService from './services/AuthService.service';
import {
    BrowserRouter as Router, Route, Switch, Redirect
} from "react-router-dom";

import AuthRoute from "./components/AuthRoute/AuthRoute"
import Home from './views/Home/home';
import Devices from './views/Devices/devices';
import GrindingsView from './views/Grindings/grindings';
import MainLayout from './layouts/MainLayout';
import LoginView from './views/Login/login';
import DeviceDetails from './views/DeviceDetails/deviceDetails';
import Franchisees from './views/Franchisees/franchisee';
import FranchiseeDetails from './views/FranchiseeDetails/franchseeDetails';


class App extends React.Component<any, any> {
    authService: AuthService = new AuthService();

    async componentDidMount() {

    }

    render() {
        return (
            <Router>
                <Switch>
                    <AuthRoute exact={true} path="/devices" render={(props: any) => <MainLayout><Devices {...props} /></MainLayout>} />
                    <AuthRoute exact={true} path="/franchisees" render={(props: any) => <MainLayout><Franchisees {...props} /></MainLayout>} />
                    <AuthRoute path="/grindings" render={(props: any) => <MainLayout><GrindingsView {...props} /></MainLayout>} />
                    <AuthRoute exact={true} path="/devices/:deviceId" render={(props: any) => <MainLayout><DeviceDetails {...props} /></MainLayout>} />
                    <AuthRoute exact={true} path="/franchisees/new" render={(props: any) => <MainLayout><FranchiseeDetails {...props} /></MainLayout>} />
                    <AuthRoute exact={true} path="/franchisees/:franchiseeId" render={(props: any) => <MainLayout><FranchiseeDetails franchiseeId={props.match.params.franchiseeId} {...props} /></MainLayout>} />
                    <Route path="/login" render={(props: any) => <LoginView {...props} />} />
                    <Redirect to="/devices" />
                </Switch>
            </Router>
        );
    }
}
export default App;
