import { GridReadyEvent } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React from "react";
import { DeviceDTO, DevicesClient, IDeviceDTO } from "../../api/BikeCafeApi";
import AddHeaderRenderer from "../../components/AgGrid/AddHeaderRenderer";
import DeviceMenuRenderer from "../../components/AgGrid/DeviceMenuRenderer";
import IsActiveCellRenderer from "../../components/AgGrid/IsActiveRenderer";
import Navbar from "../../components/Navbar/Navbar";
import MainLayout from "../../layouts/MainLayout";
import AddDeviceModal, { INewDevice } from "../../components/Modals/AddDeviceModal/AddDeviceModal"
import { Card, Modal } from "antd";

import "./devices.scss"
import { ExclamationCircleOutlined } from "@ant-design/icons";
import IsActivatedCellRenderer from "../../components/AgGrid/IsActivatedRendere";

export interface IDevicesState {
    devices: IDeviceDTO[],
    visible: {
        addDeviceModal: boolean
    },
    editDevice?: DeviceDTO,
}

enum ModalType { AddDeviceModal }

class Devices extends React.Component<any, IDevicesState> {

    apiClient: DevicesClient = new DevicesClient();

    constructor(props: any) {
        super(props);

        this.state = {
            visible: {
                addDeviceModal: false
            },
            devices: [],
        }
    }

    async componentDidMount() {
        var data = await this.apiClient.list();
        this.setState({
            devices: data
        });
    }

    onGridReady = (event: GridReadyEvent) => {
        event.api.sizeColumnsToFit();
    }

    onShowAddDeviceModal = () => {
        this.setState({ editDevice: undefined })
        this.changeVisible(ModalType.AddDeviceModal, true);
    }

    onCreate = async (values: DeviceDTO) => {
        if (values.id) {
            const newDevice = DeviceDTO.fromJS({ ...values })
            let device = await this.apiClient.update(newDevice)
            let devices = [...this.state.devices];
            var index = devices.findIndex(x => x.id == device.id);
            if (index > -1) {
                devices[index] = device;
            }
            this.setState({ devices: devices });
        } else {
            const newDevice = DeviceDTO.fromJS({ ...values, id: 0, isActive: 1 })
            let device = await this.apiClient.create(newDevice)
            let devices = [...this.state.devices];
            devices.push(device);
            this.setState({ devices: devices });
        }

        this.changeVisible(ModalType.AddDeviceModal, false);
    }

    onEdit = (device: DeviceDTO) => {
        this.setState({
            editDevice: device,
            visible: {
                ...this.state.visible,
                addDeviceModal: true
            }
        });
    }

    onDetails = (device: IDeviceDTO) => {
        this.props.history.push(`/devices/${device.key}`)
    }

    onDelete = (device: IDeviceDTO) => {
        Modal.confirm({
            title: 'Czy na pewno chcesz usunąć urządzenie?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Tak',
            okType: 'danger',
            cancelText: 'Nie',
            onOk: async () => {
                var response = await this.apiClient.delete(device.id as any);
                if (response) {
                    let devices = [...this.state.devices];
                    let index = devices.indexOf(device);
                    if (index > -1) {
                        devices.splice(index, 1);
                        this.setState({ devices: devices })
                    }
                }
            },
            onCancel: () => { },
        });
    }

    changeVisible = (modal: ModalType, visible: boolean) => {
        let v = { ...this.state.visible }
        if (modal === ModalType.AddDeviceModal) {
            v.addDeviceModal = visible;
        }
        this.setState({ visible: v });
    }

    render() {
        return (
            <>
                <div className="row h-100">
                    <div className="col">
                        <Card title="Urządzenia" className="w-100 h-100">
                            <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
                                <AgGridReact
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.devices}>
                                    <AgGridColumn filter={true} floatingFilter={true} field="name" headerName="Nazwa" resizable={true}></AgGridColumn>
                                    <AgGridColumn filter={true} floatingFilter={true} field="franchiseeName" headerName="Franczyzobiorca" resizable={true}></AgGridColumn>
                                    <AgGridColumn filter={true} floatingFilter={true} field="millIdentifier" headerName="Nr seryjny młynka" resizable={true}></AgGridColumn>
                                    <AgGridColumn field="isActivated" headerName="Aktywowany" resizable={true}
                                        cellRendererFramework={IsActivatedCellRenderer}
                                    ></AgGridColumn>
                                    <AgGridColumn field="onlineState" headerName="Aktywność" resizable={true} cellRendererFramework={IsActiveCellRenderer}></AgGridColumn>
                                    <AgGridColumn field="id" headerName="" resizable={true}
                                        width={320}
                                        cellRendererFramework={DeviceMenuRenderer}
                                        cellRendererParams={{
                                            onEdit: this.onEdit,
                                            onDetails: this.onDetails,
                                            onDelete: this.onDelete,
                                        }}
                                    // headerComponentFramework={AddHeaderRenderer}
                                    // headerComponentParams={{
                                    //     onCreate: this.onShowAddDeviceModal
                                    // }}
                                    ></AgGridColumn>
                                </AgGridReact>
                            </div>
                        </Card>
                    </div>
                </div>
                <AddDeviceModal device={this.state.editDevice} handleCancel={() => { this.changeVisible(ModalType.AddDeviceModal, false) }} handleOk={(values) => { this.onCreate(values) }} visible={this.state.visible.addDeviceModal} />
            </>
        )
    }
}

export default Devices;