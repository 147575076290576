import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { GridApi, GridReadyEvent, PaginationChangedEvent } from "ag-grid-community";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { Button, Card } from "antd";
import axios, { CancelToken, CancelTokenSource, CancelTokenStatic } from "axios";
import React from "react";
import { GrindClient, Pagination } from "../../api/BikeCafeApi";
import MainLayout from "../../layouts/MainLayout";
import "./grindings.scss";

class GrindingsView extends React.Component<any, any> {
    apiClient: GrindClient = new GrindClient();

    cancelToken: CancelTokenStatic = axios.CancelToken;
    cancelTokenSource = this.cancelToken.source();

    grid: GridApi;

    constructor(props: any) {
        super(props);

        this.state = {
            page: 1,
            perPage: 1000,
            totalPages: 0,
            visible: {
                addDeviceModal: false
            },
            grinds: [],
        }
    }

    async componentDidMount() {

        var data = await this.apiClient.list(new Pagination({ page: this.state.page, perPage: this.state.perPage }));
        this.setState({
            grinds: data.entities,
            totalPages: data.totalPages
        });

        //setInterval(async () => {
        //    var data = await this.apiClient.list(new Pagination({ page: this.state.page, perPage: this.state.perPage }));
        //    this.setState({
        //        grinds: data.entities
        //    });
        //}, 5000)
    }

    onGridReady = (event: GridReadyEvent) => {
        event.api.sizeColumnsToFit();
        this.grid = event.api;
    }

    dateFormatter = (params: any) => {
        return params.value.format("YYYY-MM-DD HH:mm:ss");
    }

    timeFormatter = (params: any) => {
        return (params.value / 1000).toFixed(3) + " [s]";
    }

    onPaginationChanged = (event: PaginationChangedEvent) => {
        console.log(event);
    }

    goToPage = (page: number) => {
        if (page < 1 || page > this.state.totalPages) {
            return;
        }

        this.cancelTokenSource.cancel();

        this.cancelToken = axios.CancelToken;
        this.cancelTokenSource = this.cancelToken.source();


        this.grid.showLoadingOverlay();
        this.setState({ page: page }, async () => {
            var data = await this.apiClient.list(new Pagination({ page: this.state.page, perPage: this.state.perPage }), this.cancelTokenSource.token);
            this.setState({
                grinds: data.entities,
                totalPages: data.totalPages
            });
            this.grid.hideOverlay();
        });

       
    }

    render() {
        return (
            <div className="row h-100">
                <div className="col">
                    <Card title={
                        <div className="row">
                            <div className="col">Pomiary</div>
                            <div className="col text-right d-flex justify-content-end align-items-center" style={{userSelect: "none"}}>
                                <DoubleLeftOutlined onClick={() => this.goToPage(1)} />
                                <LeftOutlined onClick={() => this.goToPage(this.state.page - 1)} />
                                <div className="d-inline-block ms-2 me-2">Strona <strong>{this.state.page}</strong> z <strong>{this.state.totalPages}</strong></div>
                                <RightOutlined onClick={() => this.goToPage(this.state.page + 1)} />
                                <DoubleRightOutlined onClick={() => this.goToPage(this.state.totalPages)} />
                            </div>
                        </div>
                    } className="w-100 h-100">
                        <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
                            <AgGridReact
                                onGridReady={this.onGridReady}
                                rowData={this.state.grinds}

                                suppressPaginationPanel={true}
                            >

                                <AgGridColumn field="deviceName" headerName="Nazwa" resizable={true}></AgGridColumn>
                                <AgGridColumn field="deviceKey" headerName="Identyfikator" resizable={true}></AgGridColumn>
                                <AgGridColumn field="createDate" headerName="Data" resizable={true} valueFormatter={this.dateFormatter}></AgGridColumn>
                                <AgGridColumn field="time" headerName="Czas trwania" resizable={true} valueFormatter={this.timeFormatter}></AgGridColumn>
                            </AgGridReact>
                        </div>
                    </Card>
                </div >
            </div >
        );
    }
}

export default GrindingsView;