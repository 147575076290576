import { Card, DatePicker, Form } from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { StatisticsClient, StatisticsMillDTO } from "../../api/BikeCafeApi";

import "./franchseeDetails.scss";

const colors = ['#003f5c',
    '#ffa600',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600']

class FranchiseeStatistics extends React.Component<any, any> {
    statisticsClient: StatisticsClient = new StatisticsClient();

    constructor(props: any) {
        super(props);
        this.state = {
            range: [moment().startOf('month'), moment()],
            data: new StatisticsMillDTO({ totalCoffee: 0, millCount: 0 })
        }
    }

    public async componentDidMount() {
        await this.populateData(moment().startOf('month'), moment());
    }

    async populateData(from: Moment, to: Moment) {
        var data = await this.statisticsClient.millStatistics(this.franchiseeId, moment(from.format('yyyy-MM-DD')), moment(to.format('yyyy-MM-DD')));
        this.setState({ data: data, range: [from, to] });
    }

    onChangeRange = (range: [Moment, Moment]) => {
        this.populateData(range[0], range[1]);
    }

    get franchiseeId() {
        return this.props.franchiseeId;
    }

    render() {
        return (<div className="franchiseeStatistics">
            <Card title="Statystyki">
                <div>
                    Zakres dat: <DatePicker.RangePicker value={this.state.range} onChange={(value: any, dateStrings) => this.onChangeRange(value)} />
                </div>
                <div style={{ width: "100%", height: 400 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={this.state.data.data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {this.state.data?.devices?.map((device: any, index: number) => (<Bar key={device.key} name={device.name} dataKey={device.key} stackId="main" fill={colors[index]} />))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <div>
                    <div className="row">
                        <div className="col-2 text-right">Ilość zmielonej kawy</div>
                        <div className="col-10"><strong>{this.state.data.totalCoffee.toFixed(2)} kg</strong></div>
                    </div>
                    <div className="row">
                        <div className="col-2 text-right">Ilość mieleń</div>
                        <div className="col-10"><strong>{this.state.data.millCount}</strong></div>
                    </div>
                </div>


            </Card>




        </div>)
    }
}

export default FranchiseeStatistics;