import { GridReadyEvent, ValueFormatterParams } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Card } from "antd";
import moment from "moment";
import React from "react";
import { log } from "util";
import { DeviceLogDTO, LogsClient, LogType } from "../../../api/BikeCafeApi";

import "./DeviceLogs.scss";

export interface IDeviceLogs {
    deviceId: number,
    logs: LogType[]
}

const dateFormatter = (params: ValueFormatterParams) => {
    return (params.value as moment.Moment).format('YYYY-MM-DD HH:mm');
}

const typeFormatter = (params: ValueFormatterParams) => {
    let type = (params.value as LogType);
    switch (type) {
        case LogType.OfflineStatus: return "Przejście w offline";
        case LogType.OnlineStatus: return "Przejście w online";
        default: return "";
    }
}

const valueFormatter = (params: ValueFormatterParams) => {
    let data: DeviceLogDTO = params.data;
    if (data.type == LogType.OfflineStatus || data.type == LogType.OnlineStatus) {
        return moment.utc(data.value).local().format('YYYY-MM-DD HH:mm:ss');
    }
    return "";
}

class DeviceLogs extends React.Component<IDeviceLogs, any>{
    logClient: LogsClient = new LogsClient();

    state = {
        logs: []
    }

    async componentDidMount() {
        var logs = await this.logClient.logs(this.props.deviceId, this.props.logs);
        this.setState({ logs: logs })
    }

    onGridReady = (event: GridReadyEvent) => {
        event.api.sizeColumnsToFit();
    }

    render() {
        return (<Card title="Logi" className="deviceLogs ag-theme-alpine" >
            <AgGridReact
                onGridReady={this.onGridReady}
                rowData={this.state.logs}>
                <AgGridColumn filter={true} width={50} floatingFilter={true} field="insertDate" headerName="Data dodania" resizable={true} valueFormatter={dateFormatter}></AgGridColumn>
                <AgGridColumn filter={true} width={50} floatingFilter={true} field="type" headerName="Type" resizable={true} valueFormatter={typeFormatter} ></AgGridColumn>
                <AgGridColumn filter={true} floatingFilter={true} field="value" headerName="Wartość" resizable={true} valueFormatter={valueFormatter}></AgGridColumn>
            </AgGridReact>
        </Card>)
    }
}

export default DeviceLogs