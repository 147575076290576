import { AuthClient, LoginRequest, Role } from "../api/BikeCafeApi";
import { set, remove, get } from "local-storage";
import jwt_decode from "jwt-decode";

export class AuthService {
    static tokenKey = "bikecafe_token";

    authClient: AuthClient = new AuthClient();

    public async login(login: string, password: string) {
        const response = await this.authClient.authenticate(new LoginRequest({ login: login, password: password }));
        if (response.token) {
            set<string>(AuthService.tokenKey, response.token);
        }
        return response;
    }

    public logout() {
        remove(AuthService.tokenKey)
    }

    public getUserName(): string {
        if (get(AuthService.tokenKey)) {
            var token: any = jwt_decode(get(AuthService.tokenKey))
            return token["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        }
        return "";
    }

    public getFranchiseeId(): string {
        if (get(AuthService.tokenKey)) {
            var token: any = jwt_decode(get(AuthService.tokenKey))
            return token["Franchisee"];
        }
        return "";
    }

    public getRole(): Role | null {
        if (get(AuthService.tokenKey)) {
            var token: any = jwt_decode(get(AuthService.tokenKey))

            let rolestring = token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            switch (rolestring) {

                case "Admin": return Role.Admin;
                case "Owner": return Role.Owner;
                case "User": return Role.User;
            }
        }
        return null;
    }
}

export default AuthService;