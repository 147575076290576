import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css"
import 'antd/dist/antd.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "./assets/css/paper-dashboard.css";
import './index.scss';


import configureStore from './redux/store';

ReactDOM.render(
    <Provider store={configureStore({})}>
        <Suspense fallback={<div className="loading" />}>
            <NiceModal.Provider>
                <App />
            </NiceModal.Provider>
        </Suspense></Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
