import { get } from "local-storage";
import AuthService from "../../services/AuthService.service";
import { LOGIN, LOGOUT } from "./auth.actions";

const INIT_STATE = {
    token: get<string>(AuthService.tokenKey) || ""
};

const authReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case LOGOUT: return { ...state, token: "" };
        case LOGIN: return { ...state, token: action.payload };
        default: return { ...state };
    }
}

export default authReducer;
