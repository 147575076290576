import { Card, Form, Input, Button, Checkbox, notification } from "antd";
import React from "react";
import logo from "../../assets/img/logo-main.svg";
import logo_login from "../../assets/img/logo_login.png";

import { connect } from "react-redux";
import AuthService from "../../services/AuthService.service";

import "./login.scss"
import { setToken } from "../../redux/reducers/auth.actions";
import { Redirect } from "react-router";

import background from "../../assets/img/login_background.jpg";


class LoginView extends React.Component<any, any> {

    authClient: AuthService = new AuthService();

    onFinish = async (values: any) => {
        var response = await this.authClient.login(values.login, values.password);
        if (response.token) {
            this.props.setToken(response.token);
        } else {
            console.log('asd');
            notification["error"]({
                message: "Niepoprawny login lub hasło"
            })
        }
    };

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    render() {
        if (this.props.token) {
            return <Redirect to="/devices" />
        }
        return (
            <div className="loginView" style={{ backgroundImage: `url(${background})` }}>
                <div>
                    <img src={logo_login} className="logo mb-4" />
                    <Card style={{ width: 600 }} className="cardContainer">
                        <Form
                            name="basic"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            initialValues={{}}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item
                                label="Login"
                                name="login"
                                rules={[{ required: true, message: 'Pole wymagane!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Pole wymagane!' }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
                                <Button type="primary" htmlType="submit">
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>)
    }
}

const mapStateToProps = (state: any) => {
    return {
        token: state.auth.token
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setToken: (token: any) => dispatch(setToken(token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);