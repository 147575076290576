import { faEdit, faInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICellRendererParams } from "ag-grid-community";
import { Button } from "antd";
import React from "react";

class DeviceMenuRenderer extends React.Component<ICellRendererParams, any> {
    render() {
        return (<div style={{ textAlign: "right" }}>
            <Button icon={<FontAwesomeIcon icon={faInfo} fixedWidth={true} />} size={"middle"} onClick={() => (this.props as any).onDetails(this.props.data)}> Szczegóły</Button>
            {/* <Button icon={<FontAwesomeIcon icon={faEdit} fixedWidth={true} />} size={"middle"} onClick={() => (this.props as any).onEdit(this.props.data)}> Edycja</Button> */}
            {/* <Button danger={true} size={"middle"} onClick={() => (this.props as any).onDelete(this.props.data)}><FontAwesomeIcon icon={faTrash} fixedWidth={true} /> Usuń</Button> */}
        </div>)
    }
}

export default DeviceMenuRenderer;