import { useModal } from "@ebay/nice-modal-react";
import { faInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridReadyEvent } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Button, Card } from "antd";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { ActiveStatus, Role, UserDTO, UsersClient } from "../../../api/BikeCafeApi";
import AddHeaderRenderer from "../../../components/AgGrid/AddHeaderRenderer";
import UserModal from "../../../components/Modals/UserModal/UserModal";

function FranchiseeUsers(props: any) {
    const userClient = new UsersClient();
    const userModal = useModal(UserModal);

    var [users, setUsers] = useState<UserDTO[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const data = await userClient.list(props.franchiseeId);
        setUsers(data);
    }

    const onShowUserForm = useCallback((user?: UserDTO) => {
        console.log('asd', user);
        userModal.show({ user: user, franchiseeId: props.franchiseeId }).then(() => {
            fetchData();
        });
    }, [userModal]);

    function onGridReady(event: GridReadyEvent) {
        console.log(event);
    }

    //function onDetails(entity: IFranchiseeDTO) => {
    //    this.props.history.push(`/franchisees/${entity.id}`)
    //}


    return (<Card style={{ height: "80vh" }} className="ag-theme-alpine">
        <AgGridReact rowData={users} onGridReady={onGridReady}>
            <AgGridColumn filter={true} floatingFilter={true} field="login" headerName="Login" resizable={true}></AgGridColumn>
            <AgGridColumn filter={true} floatingFilter={true} field="role" headerName="Rola" resizable={true}
                cellRenderer={(props) => {
                    let user: UserDTO = props.data;
                    switch (user.role) {
                        case Role.Owner: return "Właściciel";
                        case Role.User: return "Sprzedawca";
                        default: return "";
                    }
                }}
            ></AgGridColumn>
            <AgGridColumn field="isActive" headerName="Status" resizable={true} cellRendererFramework={(props: any) => {
                let user: UserDTO = props.data;
                return (<div>
                    {user.isActive == ActiveStatus.Active ? <span className="badge bg-success">Aktywny</span> : <span className="badge bg-danger">Nie aktywny</span>}
                </div>)
            }}></AgGridColumn>
            <AgGridColumn field="id" headerName="" resizable={true}
                flex={1}
                width={320}
                headerComponentFramework={(props: any) => <div style={{ textAlign: "right" }}>
                    <button onClick={() => onShowUserForm()} className="btn btn-success btn-sm"><FontAwesomeIcon icon={faPlus} fixedWidth={true} />  Dodaj</button>
                </div>}
                cellRendererFramework={(props: any) => {
                    return (
                        <div style={{ textAlign: "right" }}>
                            <Button icon={<FontAwesomeIcon icon={faInfo} fixedWidth={true} />} size={"middle"} onClick={() => onShowUserForm(props.data)}> Szczegóły</Button>
                        </div>)
                }}
            ></AgGridColumn>
        </AgGridReact>
    </Card>)
}

export default FranchiseeUsers;