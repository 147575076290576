import { ICellRendererParams } from "ag-grid-community";
import React from "react";

class IsActivatedCellRenderer extends React.Component<ICellRendererParams, any> {
    render() {
        return ((<div>
            {!this.props.value && <span className="badge bg-danger">Nie aktywowany</span>}
            {this.props.value && <span className="badge bg-success">Aktywowany</span>}
        </div>))
    }
}

export default IsActivatedCellRenderer;